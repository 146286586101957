/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var pageY = 0,
		siteHeader = $('.site-header'),
		headerRow = siteHeader.find('.row'),
		topGap = siteHeader.outerHeight(true),
		menuButton = $('.menu-button'),
		menuOpen = $('.menu-open'),
		menuClose = $('.menu-close'),
		headShape = $('.home-heading .shape'),
		headImage = $('.home-heading .header-image'),
		headWrap = $('.home-heading .img-wrap');

	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - topGap
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Adjust margin for inner page

		if ( !$('body').hasClass('home') ) {
			$('#content').css( 'margin-top', topGap );
		}

		// Intro Animations

		$('#page').addClass( 'init' );

		// Remove videos autoplay when the user has requested
		// the operating system to minimize the amount of motion it uses

		var video = $('video');
		if ( window.matchMedia('(prefers-reduced-motion)').matches ) {
			video.removeAttribute('autoplay');
			video.pause();
		}

		// Items filtering

		$('.item-filters .filter').on('click', function(event) {
			event.preventDefault();
			$('.item-filters .filter').removeClass('active');
			$(this).addClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onStart: function() {
				$('body').addClass( 'open-menu' );
			},
			onReverseComplete: function() {
				$('body').removeClass( 'open-menu' );
			}
		});

		menu.add(
			'start'
		).to(
			'#menu-offcanvas .left',
			{
				yPercent: -100,
				duration: 1,
				ease: 'Power2.inOut'
			},
			'start'
		).to(
			'#menu-offcanvas .right',
			{
				yPercent: 100,
				duration: 1,
				ease: 'Power2.inOut'
			},
			'start'
		).from(
			'#menu-offcanvas .menu-item',
			{
				yPercent: 120,
				opacity: 0,
				delay: 0.5,
				duration: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'start'
		).from(
			'#menu-offcanvas .languages, #menu-offcanvas .site-contacts',
			{
				yPercent: -120,
				opacity: 0,
				delay: 0.5,
				duration: 1,
				ease: 'Power2.inOut'
			},
			'start'
		).from(
			'#menu-offcanvas .left .menu-top',
			{
				yPercent: 120,
				opacity: 0,
				delay: 0.75,
				duration: 1,
				ease: 'Power2.inOut'
			},
			'start'
		).from(
			'#menu-offcanvas .right .menu-top',
			{
				yPercent: -120,
				opacity: 0,
				delay: 0.75,
				duration: 1,
				ease: 'Power2.inOut'
			},
			'start'
		);

		// Backgrounds
		var sections = gsap.utils.toArray('.gradient');

		if ( sections.length > 0 ) {
			sections.forEach((section) => {
				if ( section.getAttribute('data-bgcolor' ) !== null) {
					var bgcolor = section.getAttribute('data-bgcolor'),
						tl = gsap.timeline({
							immediateRender: false,
							scrollTrigger: {
								trigger: section,
								start: 'top bottom',
								end: '+=100%',
								scrub: true,
								toggleActions: 'restart pause reverse pause'
							}
						});

					tl.to(
						'#content',
						1,
						{
							backgroundColor: bgcolor,
						}
					);
				}
			});
		}

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		titles.forEach((title) => {
			var delay = title.getAttribute('data-delay'),
				scroll = title.getAttribute('data-scroll'),
				start = 'top center';

			if ( scroll == 'no' ) start = 'top bottom';

			title.parentNode.style.overflow = 'hidden';

			gsap.from(
				title,
				{
					delay: delay,
					duration: 1.5,
					yPercent: 120,
					scrollTrigger: {
						trigger: title,
						start: start
					}
				}
			);
		});

		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		texts.forEach((text) => {
			var delay = text.getAttribute('data-delay'),
				scroll = text.getAttribute('data-scroll'),
				from = text.getAttribute('data-range-in') ? text.getAttribute('data-range-in') : 0,
				to = text.getAttribute('data-range-out') ? text.getAttribute('data-range-out') - 0.02 : $(window).width() + 1,
				start = 'top center';

			if ( scroll == 'no' ) start = 'top bottom';

			if ( $(window).width() >= from && $(window).width() < to ) {
				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start
						}
					}
				);
			}
		});

		// Images reveal
		var containers = gsap.utils.toArray('.reveal');

		if ( containers.length > 0 ) {
			containers.forEach((container) => {
				var image = container.querySelector('img'),
					tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							start: 'top center'
						}
					});
				
				tl.set(
					container,
					{
						autoAlpha: 1
					}
				);

				if ( container.classList.contains('right-side') ) {
					tl.from(
						container,
						2,
						{
							xPercent: 100,
							ease: 'Power2.easeOut'
						}
					);

					tl.from(
						image,
						2,
						{
							xPercent: -100,
							scale: 1.3,
							delay: -2,
							ease: 'Power2.easeOut'
						}
					);
				} else {
					tl.from(
						container,
						2,
						{
							xPercent: -100,
							ease: 'Power2.easeOut'
						}
					);

					tl.from(
						image,
						2,
						{
							xPercent: 100,
							scale: 1.3,
							delay: -2,
							ease: 'Power2.easeOut'
						}
					);
				}
			});
		}

		if ( $(window).width() >= 1024 ) {
			// Initial animations
			if ( siteHeader.length > 0 ) {
				gsap.from(
					headerRow,
					{
						delay: 0.75,
						duration: 0.7,
						yPercent: 130
					}
				);
			}

			if ( headShape.length > 0 ) {
				gsap.from(
					headShape,
					{
						delay: 1.25,
						duration: 0.7,
						yPercent: 10,
						opacity: 0
					}
				);
			}

			if ( headWrap.length > 0 ) {
				gsap.from(
					headWrap,
					{
						delay: 1.5,
						duration: 0.7,
						yPercent: 40,
						opacity: 0
					}
				);
			}

			// Heading Home
			if ( headImage.length > 0 ) {
				gsap.to(
					headImage,
					{
						scale: 0,
						opacity: 0,
						duration: 1,
						ease: 'none',
						scrollTrigger: {
							trigger: '.home-heading',
							start: '80% 100%',
							scrub: true
						}
					}
				);
			}

			// Home image
			var image =  $('#extra-home-content img')

			if ( image.length > 0 ) {
				var scale = $(window).width() >= 768 ? 0.5 : 0.7;

				gsap.to(
					image,
					{
						scale: scale,
						borderTopLeftRadius: '9999em',
						borderTopRightRadius: '9999em',
						duration: 1,
						ease: 'Power2.inOut',
						scrollTrigger: {
							trigger: '#extra-home-content .wrapper',
							start: 'top top',
							end: '+=100%',
							scrub: true,
							pin: true
						}
					}
				);
			}

			// Reasons
			var reasons = gsap.utils.toArray('.reason');

			reasons.forEach((reason) => {
				gsap.to(
					reason,
					{
						opacity: 1,
						scale: 1,
						ease: 'Power2.inOut',
						scrollTrigger: {
							trigger: reason,
							start: 'top bottom',
							end: '+=500px',
							scrub: true
						}
					}
				);
			});

			// Waves swaying (reveal)
			var waves = gsap.utils.toArray('[class$="-wave"]');

			waves.forEach((wave) => {
				var type = wave.getAttribute('data-wave'),
					start = '0% 70%',
					duration = 2;
				
				if ( type == 'double' ) {
					start = '0% 40%';
					duration = 1.5;
				}
				
				gsap.to(
					wave,
					{
						right: 0,
						duration: duration,
						ease: 'Power2.inOut',
						scrollTrigger: {
							trigger: wave,
							start: start,
							toggleActions: 'play none none reverse'
						}
					}
				);
			});

			// Rooms
			var roomImage = $('#rooms .image1 img');

			if ( roomImage.length > 0 ) {
				gsap.to(
					roomImage,
					{
						scale: 0.5,
						borderTopLeftRadius: '9999em',
						borderBottomLeftRadius: '9999em',
						duration: 1,
						ease: 'Power2.inOut',
						scrollTrigger: {
							trigger: roomImage,
							start: '25% 75%',
							end: '+=100%',
							scrub: true,
							once: true
						}
					}
				);
			}
		}

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass( 'collapsed' );

			if ( ! $(this).hasClass('collapsed') ) {
				menu.play();
			} else {
				menu.reverse();
			}

			$('body').toggleClass( 'open-menu' );
		});

		menuOpen.on('click', function(e) {
			e.preventDefault();

			menu.play();
		});

		menuClose.on('click', function(e) {
			e.preventDefault();

			menu.reverse();
		});

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	$( window ).on( 'resize', function() {

		

	});

	// Code to run when the browser window scrolls

	$( window ).on( 'scroll', function() {

		if ( $(window).width() >= 1024 ) {
			pageY = window.scrollY;

			if ( pageY > 0 ) {
				siteHeader.addClass( 'moved' );
			} else {
				siteHeader.removeClass( 'moved' );
			}
		}

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Room names slideshow
const roomsSlider = new Swiper('#rooms .rooms-slideshow', {
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	allowTouchMove: false,
	speed: 15000,
	autoplay: {
		delay: 5,
		disableOnInteraction: false,
	},
});

// Room gallery
const roomGallery = new Swiper('#extra-room-content .rooms-slideshow', {
	loop: true,
	effect: 'slide',
	slidesPerView: 1,
	speed: 1000,
	navigation: {
		nextEl: '.rooms-nav-next',
		prevEl: '.rooms-nav-prev',
	},
});

// Offers slideshow
const offersSlider = new Swiper('#offers .offers-slideshow', {
	loop: true,
	effect: 'slide',
	slidesPerView: 1,
	centeredSlides: true,
	speed: 500,
	navigation: {
		nextEl: '.offers-nav-next',
		prevEl: '.offers-nav-prev',
	},
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween: 50,
		}
	}
});

// Reasons slideshow (mobile)
const reasonsSlider = new Swiper('#reasons .reasons-slideshow', {
	loop: true,
	effect: 'slide',
	slidesPerView: 1,
	speed: 500,
	navigation: {
		nextEl: '.reasons-nav-next',
		prevEl: '.reasons-nav-prev',
	},
});

// Reviews slideshow
const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1500,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 1.25,
			spaceBetween: 90,
		},
	}
});

/* Swiper - End */
